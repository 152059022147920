.audioCell {
  i {
    cursor: pointer;
  }
  .audioModalContainer {
    display: none;
    position: absolute;
    flex-direction: column;
    top: -0.3rem;
    left: 3rem;
    z-index: 2;
    .audioStyling {
      border-radius: 60px;
      box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.75);
      &::-webkit-media-controls-panel {
        background-color: #d9d9d9;
      }
      &::-webkit-media-controls-play-button {
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
