.newHopComment {
  background-color: #fff5c5;
  border: 5px solid #f27b36;
  padding: 5px;
  margin-bottom: 5px;
}
.hopCommentDetail {
  @media (max-width: 577px) {
    padding: 3%;
  }
}
.markAsRead {
  display: flex;
  align-items: center;
  .checkbox {
    accent-color: #47996f;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
