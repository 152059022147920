.checkboxContainer {
  @media all and(max-width:576px) {
    flex-direction: column;
    padding-top: 0.5rem;
    label {
      width: 100%;
    }
    .btn {
      width: 100%;
      text-align: start;
      padding: 0.375rem 0.75rem 0.375rem 0;
    }
  }
}
