.card-join,
.card-account {
  margin: 4rem 0 8rem 0;

  .card-header {
    padding: 15px 20px;
  }

  .card-body {
    padding-top: 1rem;
    padding-bottom: 7rem;
  }

  .form-group {
    margin-bottom: $account-form-group-margin-bottom;
  }

  .btn-join {
    width: 40%;

    &:nth-child(1) {
      margin-right: 1rem;

      @media (max-width: 350px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    @media (max-width: 350px) {
      width: 80%;
    }
  }
}

.form-register,
.form-account {
  background-color: $form-background;
  border: 1px solid $border-color;
  padding: 1.5rem 1rem;
}

.telecom-clickable_text {
  color: $link-textColor;
  font-family: 'Calibri';
  font-size: $second-text-fontSize;
  cursor: pointer;

  &:hover {
    color: $link-textColor;
    text-decoration: underline;
  }
}

.password-validation {
  .row {
    padding-left: 15px;
    div {
      align-items: center;
      font-size: $password-error-fontSize;
      opacity: 1;
      width: 50%;

      svg {
        margin-right: 3px;
        z-index: 100;
      }
    }

    .deactive {
      opacity: 0.4;
    }
  }
}
