.comments-select,
.provider-select,
.traceback-state-select,
.traceback-campaign-select {
  min-width: 130px;
  box-shadow: none;

  -webkit-appearance: none;
  -webkit-border-radius: 0px;

  .customselect__control,
  .provider__control,
  .traceback__control {
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    min-height: 28px;
    max-height: 28px;
    border-radius: 1px;
  }

  .css-9gakcf-option {
    background-color: $lightblue;

    &:active {
      background-color: $lightblue;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .provider__value-container,
  .traceback__value-container {
    line-height: 1;
    padding: 0 8px;

    div {
      &:nth-child(2) {
        padding: 0;
        margin: 0;
        height: 26px;
      }
    }
  }

  .provider__indicator,
  .traceback__indicator {
    padding: 0 8px;
  }

  .provider__single-value,
  .provider__placeholder,
  .provider__input,
  .traceback__placeholder,
  .traceback__input,
  .traceback__single-value {
    font-size: 15px;
  }

  .provider__input,
  .traceback__input {
    input {
      //  height: 20px;
    }
  }
}

.stirshaken-overflow > .data-table {
  overflow: visible;
}

.table-cell .customselect-small div:first-child {
  overflow: auto;
}

.customselect-small {
  .customselect__control {
    min-height: 28px;
    border-color: #e7e7e7;
    border-radius: 1px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;

    &:hover {
      border-color: #e7e7e7;
    }
  }

  .css-9gakcf-option {
    background-color: $lightblue;

    &:active {
      background-color: $lightblue;
    }
  }

  .customselect__multi-value__label {
    padding: 0 8px;
    height: 18px;
  }

  .customselect__value-container {
    line-height: 1;
    padding: 0 8px;

    div {
      &:nth-child(2) {
        padding: 0;
        margin: 0;
        height: 26px;
      }
    }
  }

  .customselect__indicator {
    padding: 0 8px;
  }

  .customselect__single-value,
  .customselect__placeholder,
  .customselect__input {
    font-size: 15px;
  }

  .customselect__input {
    input {
      height: 20px;
    }
  }
}

.customselect-large {
  .customselect__control {
    border-color: #e7e7e7;
    border-radius: 1px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    min-height: 40px;

    &:hover {
      border-color: #e7e7e7;
    }
  }

  .css-9gakcf-option {
    background-color: $lightblue;

    &:active {
      background-color: $lightblue;
    }
  }

  .customselect__multi-value__label {
    padding: 0 8px;
    height: 18px;
  }

  .customselect__value-container {
    line-height: 1;
    padding: 0 8px;

    div {
      &:nth-child(2) {
        padding: 0;
        margin: 0;
        height: 26px;
      }
    }
  }

  .customselect__indicator {
    padding: 0 8px;
  }

  .customselect__single-value,
  .customselect__placeholder,
  .customselect__input {
    font-size: 15px;
  }

  .customselect__input {
    input {
      height: 20px;
    }
  }
}
