body {
  background: linear-gradient(#fbfbfb, #ffffff);
  color: $input-textColor;
  font-size: $text-fontSize;
  font-family: Calibri !important;
}

body,
html {
  overflow-x: hidden;
}

#root {
  background-image: url('../../src/assets/images/background.png');
  background-size: 900px;
  background-repeat: no-repeat;
  background-position: 72% 10%;
  background-attachment: fixed;
}

textarea:focus {
  box-shadow: none;
  border: 0;
}

input:focus {
  box-shadow: none;
}

.page-title {
  font-family: 'Avenir-Medium';
  font-size: 2rem;
  color: $darkblue;
  margin-bottom: 2%;
}

.hide-border {
  border: none !important;
}

.form-control {
  border: 1px solid $border-color;
  font-family: 'Calibri';
  font-size: $input-text-fontSize !important;
  padding: 5px 8px !important;
  max-height: 40px !important;

  &:focus {
    border: 1px solid $border-focusColor;
    box-shadow: none;
  }
}

.custom-control {
  border: 1px solid $border-color !important;
}

.btn {
  border: 0 !important;
  box-shadow: none !important;
}

.btn-default {
  border: 0.5px lightgray solid;
}

.btn-primary {
  background: $btn-background;
  border: 0;

  &:focus,
  &:hover {
    background: #5991c1;
  }

  &:active {
    background: $btn-background !important;
  }

  &:disabled {
    background: $primary-btn-disabled;
  }
}

.btn-secondary {
  background: $primary-btn-background;
  color: $primary-btn-color;
  font-size: $button-fontSize;
  font-family: $button-fontFamily;
  padding: $button-padding;

  &:hover {
    background: $primary-btn-hover-bg;
  }

  &:disabled {
    background: $primary-btn-hover-bg;
  }
}

.btn-light {
  background: $secondary-btn-background;
  font-size: $button-fontSize;
  font-family: $button-fontFamily;
  padding: $button-padding;
  padding: 10px 20px;
}

.breadcrumb-item {
  font-family: 'Avenir-Medium' !important;
  font-size: 14px !important;
}

.breadcrum-main {
  color: $blue;
  text-transform: capitalize;
  margin-left: 30px;
}

.breadcrum-later {
  text-transform: capitalize;
}

.breadcrumb {
  position: relative;
  padding: 0rem;
  font-size: 23px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header {
  padding: 1.5rem !important;
  height: 90px !important;
  border: 0px !important;
  -webkit-box-shadow: -1px 24px 15px -23px rgba(232, 229, 232, 1);
  -moz-box-shadow: -1px 24px 15px -23px rgba(232, 229, 232, 1);
  box-shadow: -1px 24px 15px -23px rgba(232, 229, 232, 1);
}

.logo {
  width: 206px;
}

.label-bold {
  font-family: Calibri-Bold;
  font-size: $text-fontSize;
  color: $text-font-color;
  width: 100%;

  &.center {
    text-align: center !important;
  }
}

input:not([type='checkbox'], [type='radio']),
textarea {
  background: #ffffff !important;
  border-radius: 0px !important;
  outline: 0;
}

textarea.form-control {
  height: 100px;
  max-height: 200px !important;
  padding: 10px !important;
}

.form-text {
  font-family: Calibri-Bold;
  font-weight: 200;
  font-size: 16px;
}

.fixed-bottom {
  background: #e9edf2;
}

.bread-add_category {
  font-size: $input-text-fontSize;
}

.expandedRow {
  font-size: 15px !important;
  font-weight: 200;
  color: $lightgray;
  border: 0;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0;

  .special-title {
    font-family: monospace;
    font-weight: 600 !important;
  }

  .fixed-space {
    font-family: monospace;
  }
}

.table-card,
.telecom-card {
  padding: 0px;
  margin: $card-margin;
  max-width: $card-maxWidth;
  box-shadow: $card-box-shadow;
  min-height: $card-minHeight;
  @media (max-width: 577px) {
    max-width: 95%;
  }
}

.table-card-short,
.telecom-card-short {
  padding: 0px;
  margin: 0 auto 10px auto;
  max-width: $card-maxWidth;
  box-shadow: $card-box-shadow;
  min-height: 0 !important;
  //max-height: 0 !important;
}

.import-card {
  max-width: $card-maxWidth;
  box-shadow: $card-box-shadow;
  min-height: 500px !important;
  margin: $import-card-margin;
}
.upload-card {
  max-width: $card-maxWidth;
  background-color: #f2f4f6;
  min-height: 300px;
  margin: $import-card-margin;
}

.table-card-header,
.telecom-card-header {
  font-family: Avenir-Medium !important;
  background: $darkblue;
  color: $white !important;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 2% 2% 2% 4%;
}

.alert-no-margin {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.form-container {
  background: $form-background;
  border: 1px solid $secondary-border-color;
  padding: 40px 45px;
}

.footer {
  background: #e9edf2;
}

/* The form-label */

.form-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.form-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: 1px solid black;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

.form-label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.form-label input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.form-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.form-label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.WAMuiChipInput-underline-14:before {
  border-color: #e7e7e7 !important;
}

.popover {
  width: 60%;
  border: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  padding: 30px;
}

.popover-header {
  background: transparent;
  color: $darkblue;
  padding: 0;
  font-family: Calibri-Bold;
  font-weight: 600;
  font-size: 15px;
}

.popover-body {
  padding: 0;

  label {
    font-size: 15px;
    font-family: Calibri;
  }

  .checkmark {
    width: 18px;
    height: 18px;
    background: $white;
    border: 1px solid gray;
  }
}

.MuiChip-clickable {
  background: transparent !important;
}

.MuiChip-deleteIcon {
  color: #de5d5d !important;
}

.MuiChip-label {
  margin-left: 0 !important;
}

.form-label .checkmark:after {
  top: 2px;
  left: 5px;
}

.form-label input:checked ~ .checkmark {
  background: $green;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0;
}

.btn-outline-success {
  border: 1px solid $primary-btn-background !important;
  color: $primary-btn-background;
  padding-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;

  &:hover {
    background-color: $primary-btn-background;
  }
}

.popover .arrow {
  display: none;
}

.input-error {
  color: red;
  font-size: 13px;
  font-family: Calibri;
  text-align: center;
  padding-left: 8px;

  a {
    text-decoration: underline;
  }
}

.input-group-append-error {
  color: red;
}

html:not([dir='rtl']) .page-link {
  margin-left: 0px;
}

.select_box {
  width: 60px;
  overflow: hidden;
  border: 1px solid $border-color;
  position: relative;
  background: #fcfcfb;
  padding: 2px 0px;
  display: inline-flex;
  margin-right: 28px;
}

.select_box:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #4d4d4d;
  position: absolute;
  top: 40%;
  right: 10px;
  content: '';
  z-index: 98;
}

.select_box select {
  width: 90px;
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 10px;
}

.select_box select:focus {
  outline: none;
}

.pageSelection {
  border-color: #e7e7e7;
  background: #fcfcfb;
  padding: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 15px;
}

.pageSelection:focus {
  outline: none;
  box-shadow: none;
}

.pagination {
  font-family: Calibri !important;
  color: #0f72b1 !important;
  margin-bottom: 0px;
}

.page-link {
  background: #f8f8f8;
  border: 0;
  margin: 0 2px;
  cursor: pointer;
}

.page-item {
  margin: 0 3px;
}

.page-item.active {
  padding: 0;
  background: #94a2ae !important;
  color: #fff !important;
}

.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  background: transparent;
  border-color: transparent;
}

.previous {
  margin-right: 3px !important;
}

.previous,
.next {
  background: #f8f8f8;
  margin-right: 2px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #e9ecef;
    border-color: #dee2e6;
  }
}

.previous-link,
.next-link {
  padding: 5px 10px;
}

.previous-disable {
  .previous {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.next-disable {
  .next {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.break-black {
  color: #494949 !important;
}

.btn-second {
  background: rgb(245, 245, 245);
  color: #494949;
}

.card-base_main {
  min-height: 250px;
  overflow: auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

  .card-header {
    background: #07335e;
    color: #fff;
    padding: $card-header-padding !important;

    &:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
  }
}

a {
  .blue {
    color: $title-secondary-textColor !important;
  }
}

footer {
  padding-top: 10px;
  padding-bottom: 10px;
  color: $title-secondary-textColor !important;

  .footer-link {
    cursor: pointer;
    color: $title-secondary-textColor !important;
    font-family: 'Calibri';
    font-size: $text-fontSize;
  }

  .footer-info {
    margin-bottom: 0;
    color: $copyright-text-color !important;
    font-family: 'Calibri';
    font-size: $info-fontSize;
  }
}

nav {
  .nav-link {
    color: $nav-link-color;
    font-size: $nav-link-fontSize;
    text-transform: uppercase;
    font-family: 'Avenir-Light';
    margin: 0 3px;

    &:hover {
      color: $nav-link-hoverColor;
    }
  }

  .active {
    font-family: 'Avenir-Medium';
    color: $nav-link-activeColor;
  }

  .top-right-links {
    @media all and (min-width: 1200px) and (max-width: 1440px) {
      width: 350px;
    }
    .account-text {
      margin-bottom: 0px !important;
      font-size: $text-fontSize;
      font-family: 'Calibri' !important;
    }

    .account-text-link {
      color: $link-textColor !important;
      font-family: 'Calibri' !important;
      font-size: $text-fontSize !important;
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }

    .active {
      font-family: 'Calibri-Bold' !important;
    }

    span {
      padding: 0 7px;
      color: #c3e2e2;
    }
  }

  .text-ust-blue {
    color: #3b79af;

    &:hover {
      color: #07335e;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Calibri-Bold' !important;
}

h3 {
  font-size: $title-fontSize;
}

.table-breadcrumbs,
.breadcrumbs {
  padding: $breadcrumbs-padding;
  max-width: $card-maxWidth;
  margin: $breadcrumbs-margin;

  .breadcrum-main {
    color: $title-textColor;
    font-size: 30px;
  }

  .breadcrum-sub-main {
    color: $title-secondary-textColor;
    font-size: 16px;
  }

  .breadcrum-after {
    font-size: 16px;
    color: $sub-title-secondary-textColor;
  }

  .breadcrumb-item-big {
    font-family: 'Avenir-Medium' !important;
    color: $green;
    text-transform: capitalize;
    @media all and(min-width:992px) {
      font-size: 2vw;
    }
  }
}

.navbar {
  padding: $navar-padding !important;

  .navbar-brand {
    padding: 0px;
  }
}

.telecome-loading {
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  .sk-chase {
    position: fixed;
    left: 50%;
    top: 60%;
  }
}

.text-ust-green {
  color: #479a6f;
}

.text-ust {
  color: rgb(233, 31, 32) !important;
}

.text-underline {
  text-decoration: underline;
}

.base-link {
  cursor: pointer;
  color: $title-secondary-textColor !important;
  font-family: 'Calibri';
  font-size: $text-fontSize;
}

.support-card {
  .card-header {
    background-size: cover !important;
    background-position: 50% 50% !important;
  }

  .card-title {
    font-size: 50px;
  }
}

.detail-box {
  border: 1px #ddd solid;

  h5 {
    min-height: 70px;
    font-size: 16px;
  }
}

.w-60 {
  width: 60px;
}

.static-page-content {
  padding-top: 20px;
  padding-bottom: 100px;
}

#weekly-statistics {
  background-color: white;
  border: 3px solid #e8e8e8;
  font-size: 15px;
  width: 346px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -17px;
  box-shadow: 3px 2px 1px -2px rgba(217, 210, 217, 1);

  thead tr {
    border-bottom: 2px solid #e8e8e8;
  }

  th,
  td:first-child {
    font-size: 14px;
  }

  th {
    color: #b8b8b8;
  }

  th,
  td {
    padding-left: 6px;
    padding-right: 13px;
  }

  td {
    text-align: center;
  }
}

.span-cell {
  font-family: Calibri;

  &.bold {
    font-family: Calibri-Bold !important;
  }

  &.blue {
    color: $title-secondary-textColor;

    a {
      color: $title-secondary-textColor;
    }
  }

  &.red {
    color: $red;
  }

  &.gray {
    color: $input-textColor;

    a {
      color: $input-textColor;
    }
  }

  &.green {
    color: #479a6f;
  }

  &.light-blue {
    color: #25a3de;
  }

  &.pink {
    color: #de1b40;
  }

  &.ellipses {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
}

.fa-arrow-left {
  &.green {
    color: $green;
  }
}

.fa-arrow-right {
  &.red {
    color: $red;
  }
}

.fa-phone {
  margin-right: 5px;

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }
}

.fa-check {
  margin-left: 10px;

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }
}

.fa-map-marker {
  margin-left: 10px;

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }
}

.fa-circle {
  font-size: 8px;
  margin-right: 12px;
  align-self: center;

  &.green {
    color: #8fc344;
  }
}

.div-center {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.div-left {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.fixed-column {
  min-width: 170px;
  max-width: 250px;
  padding: 0 5px 0 5px;
}

.formGroup-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.tab-list {
  align-self: center;
  background-color: white;
}

.tab-list-item {
  background-color: #f6f6f6;
  display: inline-block;
  color: #262b59;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  list-style: none;
  padding: 0.5rem 0.75rem;
  font-size: 20px;
  margin-right: 1em;

  &.active {
    background-color: white;
    color: #262b59;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-top: 3px solid #43a0d0;
    border-bottom: 1px solid white;
    padding: 0.5rem 0.75rem;
    font-size: 20px;
  }

  img {
    vertical-align: unset;
    height: 20px;
    width: 20px;
  }
}

.request-nav-item {
  background: #e6e5e5;
  color: #b7b4b4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #b7b4b4;
  border-right: 2px solid #b7b4b4;
  border-top: 2px solid #b7b4b4;
  border-bottom: 2px solid #b7b4b4;
  font-size: xx-large;
  height: 125px;

  &.active {
    background: $white;
    border-left: 2px solid $white;
    border-right: 2px solid $white;
    border-top: 2px solid $white;
    border-bottom: 2px solid $white;
  }
}

.half-margin-left {
  padding-left: 0.5rem;
}

.picky {
  width: 100%;
  position: relative;
}

.picky * {
  box-sizing: border-box;
}

.picky__input {
  width: 100%;
  background-color: white;
  border-width: 1px;
  border-color: #e7e7e7;
  border-style: solid;
  border-radius: 1px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 28px;
}

.picky__input--disabled,
.picky__input--disabled + .picky__dropdown .option {
  color: rgb(95, 94, 94);
}

.picky__input::after {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  right: 16px;
  top: 12px;
}

.picky__dropdown {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 99;
  background: white;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  top: 39px;
}

.picky__dropdown .option,
.picky__dropdown li {
  list-style: none;
  background-color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  padding: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.picky__dropdown .option:hover,
.picky__dropdown li:hover {
  color: #494949;
  background-color: #deebff;
  border-color: #e7e7e7;
}

.picky__dropdown .option.selected,
.picky__dropdown li.selected {
  background-color: $lightblue;
  color: #ffffff;
  border-color: #e7e7e7;
}

.picky__dropdown .option:focus,
.picky__dropdown li:focus {
  //outline: -webkit-focus-ring-color auto 5px !important;
}

.picky__dropdown .option input[type='checkbox'],
.picky__dropdown .option input[type='radio'],
.picky__dropdown li input[type='checkbox'],
.picky__dropdown li input[type='radio'] {
  margin-right: 3px;
}

.picky__filter {
  display: flex;
  width: 100%;
  padding: 3px;
}

.picky__filter__input {
  width: 100%;
  padding: 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  height: 30px;
}

.picky__placeholder {
  color: #595959;
}

.tile-checkmark {
  width: 18px;
  height: 18px;
  background: $white;
  border: 1px solid gray;
  position: absolute;
  left: 0;
}

.tile-checkmark-incidents {
  width: 18px;
  height: 18px;
  background: $white;
  border: 1px solid #e7e7e7;
  position: absolute;
  left: 0;
}

.tile-label input:checked ~ .checkmark {
  background: $green;
}

.tile-label input:checked ~ .checkmark:after {
  display: block;
}

.tile-label {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  padding-left: 23px;
  padding-right: 10px;
}

.tile-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: 1px solid black;
}

.font-calibri-bold {
  font-family: Calibri-Bold, serif !important;
}

.incident-nav-item {
  background: #f6f6f6;
  color: #c4c5c7;
  border-left: 2px solid #e2e2e2;
  border-right: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-bottom: 2px solid #e2e2e2;
  font-size: xx-large;

  &.active {
    background: $white;
    color: #c4c5c7;
    border-left: 8px solid #5b9fcb;
    border-right-style: none;
    border-top: 2px solid #e2e2e2;
    border-bottom: 2px solid #e2e2e2;
  }
}

.text-red {
  color: $danger;
}

.text-green {
  color: $success;
}

.text-blue {
  color: $blue;
}

.text-navy {
  color: #07335e;
}

.text-grey {
  color: grey;
}

.text-light-grey {
  color: lightgray;
}

.table-cell-border-right {
  border-right: 2px solid black;
}

.text-xlarge {
  font-size: x-large;
}

.text-large {
  font-size: large;
}

.incident-campaign-header {
  color: #5b9ecb;
  font-size: 30px;
}

.incident-campaign-subheader {
  color: gray;
  font-size: 20px;
}

.incident-campaign-subheader-label {
  color: gray;
  font-family: Calibri-Bold, serif !important;
}

.incident-campaign-filter-label {
  color: gray;
}

.incident-campaign-badge {
  line-height: 1.5;
  font-size: small;
  background-color: #b2b3b5;
  color: $white;
}

.traceback-label {
  display: block;
  color: $label-textColor;
  font-size: $text-fontSize;
  font-family: 'Avenir-Light';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.traceback-heading {
  display: block;
  color: #43a0d0;
  font-size: 36px;
  font-family: 'Avenir-Light';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.traceback-heading-label {
  display: block;
  color: #43a0d0;
  font-size: $text-fontSize;
  font-family: 'Calibri';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.traceback-input {
  border-color: #e7e7e7;
  border-radius: 1px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  font-size: 15px;
  color: hsl(0, 0%, 50%);
  font-family: 'Calibri';
}

.traceback-label-invert {
  display: block;
  color: white;
  font-size: 36px;
  font-family: 'Avenir-Light';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.nav-item-horizontal:first-child {
  margin-left: 72px;
}

.campaign-search {
  font-size: 16px !important;
  height: 28px;
  width: 250px;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
}

.csd-search {
  font-size: 16px !important;
  height: 28px;
  width: 275px;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
  &.width-small {
    width: 210px !important;
  }
}

.rmdContainer {
  a {
    &:last-child {
      span {
        display: none;
      }
    }
  }
  span {
    &:last-child {
      span {
        display: none;
      }
    }
  }
}

.orange {
  color: rgb(255, 100, 0) !important;
}
.yellow {
  color: rgb(255, 174, 0) !important;
}

.hoverCardGovWidth {
  width: 265px !important;
  white-space: normal !important;
}

.no-center-alignment {
  align-items: flex-start !important;
}

.collapseMenu {
  cursor: pointer;
  font-weight: bold;
  font-size: 20;
}

.custom-flex-column-768 {
  @media all and (max-width: 768px) {
    flex-direction: column;
    align-content: flex-start;
    row-gap: 0.5rem;
  }
}

.custom-flex-column-992 {
  @media all and (max-width: 992px) {
    flex-direction: column;
    align-content: flex-start;
    row-gap: 0.5rem;
  }
}
.custom-flex-column-1200 {
  @media all and (max-width: 1200px) {
    flex-direction: column;
    align-content: flex-start;
    row-gap: 0.5rem;
  }
}

.addScroll {
  overflow-y: scroll;
}
