.modal-template {
  max-width: 40vw;
  max-height: 40vh;
  width: auto;
  height: auto;
  margin: auto;

  .modal-content {
    max-height: 100%;
  }
  .modal-body {
    padding: 20px;
  }
  .telecom-btn {
    max-height: 60px;
    &.red {
      background: rgb(214, 24, 39) !important;
    }
  }
}

@media (max-width: 577px) {
  .modal-template {
    max-width: 95vw;
    max-height: 95vh;
  }
}
@media (min-width: 425px) and (max-width: 577px) {
  .modal-template {
    margin-left: 14%;
    margin-right: 14%;
  }
}
@media (min-width: 321px) and (max-width: 424px) {
  .modal-template {
    margin-left: 8%;
    margin-right: 10%;
  }
}
@media (max-width: 320px) {
  .modal-template {
    margin-left: 2%;
    margin-right: 2%;
  }
}
