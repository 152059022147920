.setup-form {
  width: 50%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.form-container {
  width: 110%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  border-color: transparent;
}
