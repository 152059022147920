.card-body-comments {
  padding: 0;

  .fa-paperclip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .notifications {
    .sc-AykKF {
      &:nth-child(1) {
        max-width: 200px;
      }

      &:nth-child(2) {
        max-width: 60px;
      }

      &:nth-child(4) {
        min-width: 500px;
      }

      &:not(:nth-child(3)):not(:nth-child(4)) {
        justify-content: center !important;
      }
    }

    .rdt_TableHead {
      .sc-AykKF {
        &:nth-child(4),
        &:nth-child(6) {
          .rdt_TableCol_Sortable::before {
            display: none;
          }
        }
      }
    }
  }

  .data-table {
    margin: 0px 15px 0px 15px;
  }

  .hops {
    .sc-AykKF {
      &:nth-child(1) {
        max-width: 200px;
      }

      &:nth-child(3) {
        max-width: 60px;
      }

      &:nth-child(6) {
        min-width: 500px;
      }

      &:not(:nth-child(6)):not(:nth-child(4)) {
        justify-content: center !important;
      }
    }

    .rdt_TableHead {
      .sc-AykKF {
        &:nth-child(7),
        &:nth-child(6) {
          .rdt_TableCol_Sortable::before {
            display: none;
          }
        }
      }
    }
  }

  .reputation {
    .sc-AykKF {
      &:nth-child(1) {
        max-width: 200px;
      }

      &:nth-child(2) {
        max-width: 60px;
      }

      &:nth-child(5) {
        min-width: 700px;
      }

      &:not(:nth-child(5)):not(:nth-child(3)) {
        justify-content: center !important;
      }
    }

    .rdt_TableHead {
      .sc-AykKF {
        &:nth-child(5),
        &:nth-child(6) {
          .rdt_TableCol_Sortable::before {
            display: none;
          }
        }
      }
    }
  }

  .provider {
    .sc-AykKF {
      &:nth-child(1) {
        margin-left: 25px;
        max-width: 30px;
      }

      &:nth-child(3) {
        max-width: 60px;
      }

      &:nth-child(5) {
        min-width: 500px;
      }

      &:not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)) {
        justify-content: center !important;
      }
    }

    .rdt_TableHead {
      .sc-AykKF {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          .rdt_TableCol_Sortable::before {
            display: none;
          }
        }
      }
    }
  }

  .system {
    .sc-AykKF {
      &:nth-child(1) {
        margin-left: 25px;
        max-width: 200px;
      }

      &:nth-child(2) {
        max-width: 250px;
      }

      &:nth-child(3) {
        max-width: 250px;
      }

      &:nth-child(4) {
        min-width: 700px;
      }
    }
  }
}
.card-header-comments {
  padding-bottom: 20px;

  .btn {
    font-size: 15px;
    height: 28px;
    padding: 0px 10px;

    &:disabled {
      background: #aeaeae;

      &:hover {
        background: #aeaeae;
      }
    }
  }

  .form-label {
    padding-left: 23px;
    padding-right: 10px;
  }
  .checkmark {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid gray;
  }

  .checkbox-label {
    font-size: 15px;
  }

  .search-input {
    height: 28px;
    font-size: 15px !important;
  }

  .react-datepicker__input-container {
    input {
      height: 28px;
    }
  }
}

.provider-record_modal,
.reputation-comment_modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-400px, -208px);
  transition: unset !important;
  -webkit-transition: unset !important;

  .input-error {
    text-align: left;
  }

  .popover {
    transform: unset !important;
    max-width: 1000px;
    min-width: 800px;
  }

  .react-datepicker__input-container {
    input {
      height: 38px;
    }
  }
}

.provider-record_modal {
  transform: translate(-400px, -310px);
}

.input-comment {
  font-size: 15px !important;
  height: 28px;
}

.comment-filter_form {
  background-color: $form-background;
  margin: $comment-filter-margin;
}

.type {
  .traceback__control {
    background-color: $form-background;
  }
}

.comments-search {
  font-size: $input-text-fontSize !important;
  height: 28px;
  width: 250px;
  border-color: #e7e7e7;
}

.comments-search-with-border {
  height: 28px;
  width: 100%;
  padding-left: 10px;
  font-size: 16px !important;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
}

.comments-search-small {
  font-size: $input-text-fontSize !important;
  height: 28px;
  width: 160px;
  border-color: #e7e7e7;
}
.notifications-search {
  font-size: $input-text-fontSize !important;
  height: 28px;
  border-color: #e7e7e7;
}

.ip-form-group .mb-3 {
  margin-bottom: 0 !important;
}
