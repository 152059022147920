// Variable overrides
$blue: #0f72b1;
$lightblue: #2e7db6;
$darkblue: #07335e;
$darkblue-hover: #3b79af;
$white: #ffffff;
$gray: 'gray';
$lightgray: #e4e5e6;
$green: #479a6f;
$red: #d24346;
$primarybtn: #3b79af;

// @theme
$btn-background: #3b79af;
$primary-btn-background: #47996f;
$primary-btn-disabled: #83928b;
$primary-btn-hover-bg: #52ab7d;
$label-textColor: #08335d;
$primary-btn-color: #ffffff;
$input-textColor: #494949;
$input-background: #ffffff;
$border-color: #e7e7e7;
$border-color-darker: #e6e6e6;
$border-focusColor: #07335e;

$secondary-border-color: #efefef;
$secondary-btn-background: #f5f5f5;
$secondary-btn-color: #494949;
$form-background: #fcfcfc;
$form-background-darker: #f2f2f2;
$primary-link-color: #5d8fbe;
$remember-me-color: #374861;
$info-fontSize: 15px;
$text-fontSize: 16px;
$second-text-fontSize: 17px;
$input-text-fontSize: 18px;
$nav-link-fontSize: 20px;
$title-fontSize: 30px;
$welcome-title-color: #07335e;
$welcome-textColor: #223f5d;
$link-textColor: #0f72b1;
$text-gray-color: #6c757d;
$nav-link-color: #5d84b3;
$nav-link-activeColor: #163863;
$nav-link-hoverColor: #07335e;
$text-font-color: #07335e;
$login-input-text-size: 16px;
$login-input-padding: 10px;
$card-header-padding: 15px 70px;
$account-form-group-margin-bottom: 24px;
$password-error-fontSize: 13px;
$error-textColor: red;
$button-fontSize: 18px;
$button-fontFamily: 'Avenir-Medium';
$button-padding: 10px 20px;
$fadeIn-time: 1.8s;
$form-fadeIn-time: 1s;
$text-fadeIn-time: 1s;
$no-data-text-fadeIn-time: 1.5s;
$datatable-maxHeight: auto;
$datatable-minHeight: auto;
$card-minHeight: calc(100vh - #{357px});
$title-textColor: #479a6f;
$title-secondary-textColor: #0f72b1;
$sub-title-secondary-textColor: #969696;
$form-campaign-padding: 2rem 2rem 1rem 2rem;
$form-provider-padding: 1.2rem 1.2rem 0.6rem 1.2rem;
$textarea-campaign-padding: 10px;
$pagination-textColor: #7d7d7d;
$user-guidance-color: #a4a4a4;
$sub-table-bg: #546f93;
$tooltip-bg: #2b5b8a;
$navar-padding: 35px 40px 20px 20px;
$card-margin: 0px auto 40px auto;
$breadcrumbs-padding: 30px 0px;
$breadcrumbs-margin: 0px auto;
$comment-filter-margin: 15px 0px 0px 0px;
$comment-filter-padding: 24px 0px 8px 0px;
$card-sign-margin: calc((100vh - #{718px}) / 2) 0px;
$card-forgot-password-margin: calc((100vh - #{748px}) / 2) 0px;
$card-sent-email-margin: calc((100vh - #{712px}) / 2) 0px;
$card-new-password-margin: calc((100vh - #{677px}) / 2) 0px;
$card-maxWidth: 80%;
$card-box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
$page-not-found-shadow:
  -60px 0px 100px -90px rgba(0, 0, 0, 0.2),
  60px 0px 100px -90px rgba(0, 0, 0, 0.2);
$table-card-maxWidth: 100%;
$table-breadcrumbs-maxWidth: 100%;
$table-card-margin: 0 30px 40px 30px;
$import-card-margin: 5% 10% 10% 10%;
$table-breadcrumbs-margin: 0 30px;
$second-background: #fbfbfb;
$copyright-text-color: #8994a4;

$enable-negative-margins: true;
