@font-face {
  font-family: 'Futura-Medium';
  src:
    local('Futura-Medium'),
    url('./Futura-Md-BT-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Futura';
  src:
    local('Futura'),
    url('./Futura.ttc') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Calibri';
  src:
    local('Calibri'),
    url('./Calibri.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Calibri-Bold';
  src:
    local('Calibri-Bold'),
    url('./Calibri-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir-Medium';
  src:
    local('Avenir-Medium'),
    url('./Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir-Light';
  src:
    local('Avenir-Light'),
    url('./Avenir-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir-Bold';
  src:
    local('Avenir-Bold'),
    url('./Avenir-Medium.ttf') format('truetype');
  font-weight: 200;
}
