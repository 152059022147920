.cardQuarter {
  margin: 10px;
  .quarterText {
    color: $label-textColor;
  }
}

.quarter-cards {
  display: flex;
  @media (max-width: 1400px) {
    display: block;
  }
  min-width: 80%;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.halfDonutChart {
  --percentage: 0;
  --fill: rgb(13, 13, 12);
  width: 180px;
  height: 90px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  &:after {
    content: '';
    width: 180px;
    height: 180px;
    border: 40px solid;
    border-color: rgb(223, 218, 218) rgb(223, 218, 218) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
    animation: fillAnimation 1s ease-in;
  }
}

.acceptInsight {
  max-height: 30vh;
  overflow: scroll;
  background-color: rgb(231, 234, 234);
}
