.generateReportStyle {
  height: 28px;
  font-size: 12px;
  width: 150px;
  padding: 5px;
}

.user-editor-checkbox {
  width: 20px;
  height: 20px;
  margin-top: 5px;
}
.user-checkbox-label {
  position: absolute;
  padding: 5px 0px 10px 10px !important;
}
