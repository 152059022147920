div:focus {
  outline: none;
}

.telecom-text {
  color: $input-textColor;
  font-size: $input-text-fontSize;
  font-family: Calibri !important;
  width: 100%;

  .blue {
    color: $lightblue;
  }

  &.center {
    text-align: center !important;
  }

  &.padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.fontSize {
    font-size: 0.9em;
    padding-top: 5px;
  }
}

.asterisk {
  font-size: 7px;
  vertical-align: super;
}

input,
textarea {
  &::-webkit-input-placeholder {
    color: $user-guidance-color !important;
  }
}

.no-data-text {
  color: $input-textColor;
  font-size: $input-text-fontSize;
  font-family: Calibri !important;
  text-align: center;
  -webkit-animation: fadein $no-data-text-fadeIn-time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $no-data-text-fadeIn-time; /* Firefox < 16 */
  -ms-animation: fadein $no-data-text-fadeIn-time; /* Internet Explorer */
  -o-animation: fadein $no-data-text-fadeIn-time; /* Opera < 12.1 */
  animation: fadein $no-data-text-fadeIn-time;
}

.card-auth-form {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.card-sent-email,
.card-new-password,
.card-password-reset,
.card-sign {
  // margin: 100px 0px;
  border: 0px !important;
  box-shadow: 0 -30px 30px -30px rgba(0, 0, 0, 0.175) !important;

  .login-control {
    font-size: $login-input-text-size !important;
    padding: $login-input-padding !important;
  }

  .input-group-append {
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 100;
  }

  .form-label {
    padding-left: 28px;
  }

  .checkmark {
    width: 18px;
    height: 18px;
    background: #ffffff;
    margin-top: 2px;
    border: 1px solid gray;
  }
}

.table-breadcrumbs {
  margin: $table-breadcrumbs-margin;
  max-width: $table-breadcrumbs-maxWidth;
}

.table-card-short,
.table-card {
  margin: $table-card-margin;
  max-width: $table-card-maxWidth;
}

.page-notfound_img {
  margin: calc((100vh - #{662px}) / 2) 0px;
}

.customcreatable {
  .customcreatable__control {
    min-height: 28px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 1px;
    border-style: solid;
    border-width: 1px;

    &:hover {
      border-color: $border-color;
    }
  }

  .customcreatable__value-container {
    line-height: normal;
    padding: 0 8px;
  }

  .customcreatable__placeholder {
    font-size: 15px;
  }

  .customcreatable__multi-value {
    height: 16px;
    font-size: 14px;
    right: 0px;
    top: 0px;
  }

  .css-b8ldur-Input {
    height: 28px;
    padding: 0px;
  }

  .customcreatable__input {
    height: 16px;
    font-size: 15px;
  }

  .customcreatable__indicators,
  .customcreatable__indicator {
    height: 28px;
    padding: 0px;
  }

  .customcreatable__multi-value__label {
    padding: 0 8px;
    height: 24px;
    font-size: 14px;
    right: 0px;
    top: 0px;
  }
}

.comment-campaignselect {
  .customselect__control {
    border-color: hsl(0, 0%, 80%);
    border-radius: 1px;
    border-style: solid;
    border-width: 1px;
    min-height: 28px;

    &:hover {
      border-color: $border-color;
    }
  }

  .customselect__value-container {
    line-height: 1;
    padding: 0 8px;

    div {
      &:nth-child(2) {
        padding: 0;
        margin: 0;
        height: 18px;
      }
    }
  }

  .customselect__multi-value__label {
    padding: 0 8px;
    height: 18px;
  }

  .customselect__indicator {
    padding: 0 8px;
  }

  .customselect__single-value,
  .customselect__placeholder,
  .customselect__input {
    font-size: 15px;
  }

  .customselect__input {
    input {
      height: 20px;
    }
  }
}

.telecom-btn-small {
  height: 28px;
  padding: 5px;
  font-size: 14px;
}

.telecom-date-picker {
  width: 175px;
  max-width: 175px;
}

.region-dropdown {
  width: 100%;
  height: 40px;
  border: 1px solid #e7e7e7;
  font-family: 'Calibri';
}

.country-dropdown {
  width: 100%;
  height: 40px;
  border: 1px solid #e7e7e7;
  font-family: 'Calibri';
}

audio {
  background-color: transparent;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

.card-header-filters {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  .filters {
    position: absolute;
    right: 0%;
    margin-right: 35px;
    margin-top: 5px;
  }
}
.filters-homepage {
  position: absolute;
  right: 0%;
  margin-right: 75px;
  margin-top: 5px;
}

.custom-blue {
  color: $label-textColor;
}
