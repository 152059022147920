.section-provider {
  background-color: $form-background;
  border: 1px solid $border-color;
  padding: $form-provider-padding;

  a {
    color: $link-textColor;
  }
}

.card-detail-provider,
.card-add-provider {
  .input-provider {
    font-size: $input-text-fontSize !important;
  }

  .input-textarea,
  textarea {
    font-size: $input-text-fontSize;
    padding: $textarea-campaign-padding !important;
    min-height: 65px;

    &::-webkit-input-placeholder {
      font-size: $text-fontSize;
    }
  }

  .input-error {
    text-align: left;
  }

  a {
    color: $link-textColor;
  }
}

.additional-contact-table-responsive {
  padding-bottom: 10px;
}

.additional-contact-header {
  td {
    color: $label-textColor;
    font-size: 14px;
    // font-size: $text-fontSize;
    font-family: 'Calibri-Bold', serif;
    border-width: 0;

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: center;
    }
  }
}

.pcr-contact-header {
  td {
    color: $label-textColor;
    font-size: 14px;
    // font-size: $text-fontSize;
    font-family: 'Calibri-Bold', serif;
    border-width: 0;
  }
}

.additional-contact-body {
  td {
    font-size: 16px;
    // font-size: $input-text-fontSize;

    &:nth-child(4),
    &:nth-child(5) {
      text-align: center;
      padding: 0 30px;
    }

    &:nth-child(6),
    &:nth-child(7) {
      text-align: center;
    }
  }

  .active-additional-row {
    td:nth-child(4),
    td:nth-child(5) {
      padding: 0 15px;
    }
  }

  input,
  .traceback__control {
    font-size: 14px !important;
    height: 30px;
    min-height: 30px !important;
    max-height: 30px;

    .traceback__single-value {
      font-size: 14px;
    }
  }
}

.pcr-contact-body {
  td {
    font-size: 16px;
  }

  input,
  .traceback__control {
    font-size: 14px !important;
    height: 28px;
    min-height: 28px !important;
    max-height: 28px;

    .traceback__single-value {
      font-size: 14px;
    }
  }
}

.active-additional-row {
  background-color: $border-color;

  .form-control {
    margin: 3px;
  }
}

.add-contact {
  color: $title-textColor !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.add-contact {
  color: $title-textColor !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.ocn-container {
  .form-group {
    margin-bottom: 0px;
  }
}

.add-ocn {
  margin-bottom: 1.2rem;
}

.provider-admin-board {
  border-left: 1px dashed #939393;
  border-bottom: 1px dashed #939393;
}

.card-header-provider {
  padding: 20px;

  .provider-period-select {
    min-width: 130px;
  }

  input {
    font-size: 15px !important;

    &::-webkit-input-placeholder {
      font-size: 15px;
    }
  }
}

.deactivate-item {
  color: #0f72b1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.card-summary {
  .provider-detail {
    margin: 3rem 2rem 0.5rem 2rem;
  }

  .text-summary {
    margin-left: 2rem;
    color: $input-textColor;
    font-size: 30px;
  }

  .summaries-datatable {
    .rdt_TableCol_Sortable::before {
      display: none;
    }
    .sc-AykKF {
      &:nth-child(1) {
        min-width: 220px;
        justify-content: start !important;
        margin-left: 3rem !important;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        max-width: 100px;
      }

      &:not(:nth-child(1)) {
        justify-content: center !important;
      }
    }
  }

  .summaries-entiretable {
    .sc-AykKF {
      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(11) {
        min-width: 200px;
        margin-left: 3rem;
      }

      &:not(:nth-child(2)) {
        justify-content: center !important;
      }
    }
  }
}

.card-body-provider {
  padding: 0;

  .providers-datatable {
    #column-name {
      justify-content: left !important;
    }
    .rdt_TableBody {
      .sc-AykKF {
        &:nth-child(3) {
          background: #fcfcfb;
        }
      }
    }

    .sc-AykKF {
      &:nth-child(2) {
        min-width: 200px;
      }

      &:not(:nth-child(2)) {
        justify-content: center !important;
      }
    }
  }

  .expandedRow {
    margin-bottom: 20px;

    .provider-sub-table {
      border: 1px solid $sub-table-bg;

      .rdt_TableHeadRow {
        background: $sub-table-bg !important;

        .sc-AykKF {
          &:nth-child(3) {
            background: unset !important;
          }
        }
      }

      .rdt_TableBody {
        .sc-AykKF {
          &:nth-child(3) {
            background: unset !important;
          }
        }
      }

      .sc-AykKF {
        &:nth-child(1) {
          min-width: 273px;
          justify-content: start !important;
          margin-left: 1rem !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          max-width: 100px;
        }

        &:not(:nth-child(1)) {
          justify-content: center !important;
        }
      }
    }
  }
}

.provider-dropdown {
  .popover {
    padding: 0.5rem 1rem !important;
    max-width: 156px;
    // transform: translate3d(680px, 62px, 0px) !important;
    border-top: 2px solid #163863;
    border-radius: 0;
  }

  .popover-body {
    list-style: none;
    .nav-link {
      font-family: 'Avenir-Medium', serif;
      font-weight: 800;
      padding: 0;
      color: #163863;
      text-align: right !important;
      margin-bottom: 0;
      text-transform: uppercase;
      cursor: pointer;

      &:nth-child(1) {
        margin-bottom: 1rem;
      }

      i {
        font-size: 10px;
        opacity: 0.4;
        color: green;
        padding-left: 8px;
        visibility: hidden;
      }
    }

    .active {
      i {
        visibility: visible;
      }
    }
  }
}

.OCN-modal {
  max-width: 300px !important;
  max-height: 300px !important;
  margin-left: auto;
  margin-right: auto;

  .telecom-btn {
    min-width: auto;
    &.red {
      background: rgb(214, 24, 39) !important;
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-footer {
    border-top: 0;
  }
}

@media (max-width: 320px) {
  .OCN-modal {
    margin-left: 3%;
    margin-right: 3%;
  }
}
