.card-header-provider,
.card-header-traceback,
.card-header-requests,
.card-header-hops {
  padding: 20px 20px 20px 20px;
  background-color: rgba(255, 255, 255, 1);

  .form-label {
    padding-left: 23px;
    padding-right: 10px;

    &.no-padding {
      padding-right: 0;
    }
  }

  .checkmark {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid gray;
  }

  .checkbox-label {
    font-size: 15px;
  }

  .form-group {
    margin-bottom: 0px;

    input {
      height: 28px;
      font-size: 15px;
      color: $input-textColor;
      padding: 0 8px;
    }
  }

  .row {
    align-items: center;
  }
}

.form-traceback {
  background-color: $form-background;
  border: 1px solid $border-color;
  padding: $form-campaign-padding;

  .form-group {
    margin: 0 0 1.2rem 0;
  }

  .input-traceback {
    font-size: $input-text-fontSize !important;
  }

  .input-textarea,
  textarea {
    font-size: $input-text-fontSize;
    padding: $textarea-campaign-padding !important;
    min-height: 130px;

    &::-webkit-input-placeholder {
      font-size: $text-fontSize;
    }
  }

  input[type='radio'] {
    margin-right: 10px;
  }

  .border-disable {
    .react-datepicker__input-container {
      input {
        border-color: transparent;
      }
    }
  }

  .react-datepicker__input-container {
    input {
      min-height: 40px;
      padding: 5px 8px;
      font-size: $input-text-fontSize !important;
    }
  }

  a {
    color: $link-textColor;
  }

  .checkmark {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid gray;
  }
  .checkbox-label {
    font-size: 15px;
  }
}

.traceback-edit {
  .row {
    margin-top: 10px;
  }

  .traceback-strike-exempt {
    max-width: 105px;
  }
}

.traceback-view {
  margin-top: 15px;
  border-color: transparent;
  background: transparent;
  .row:last-child {
    margin-bottom: 20px;
  }
}

.react-datepicker__input-container {
  input {
    width: 100%;
    color: #494949 !important;
    border: 1px solid $border-color;
    padding: 0 8px;

    &:focus {
      outline: none;
      border: 1px solid $border-focusColor;
      box-shadow: none;
    }
  }
}

.traceback-sub-title {
  border-bottom: 1px solid #939393;
  margin-bottom: 1.5rem;
}

.traceback-holder-title {
  border-bottom: 1px solid #939393;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.card-body-traceback {
  padding: 0;

  .tracebacks-datatable {
    .sc-AykKF {
      &:nth-child(2) {
        max-width: 60px;
      }

      &:nth-child(3) {
        min-width: 200px;
      }

      &:nth-child(8) {
        min-width: 200px;
      }

      &:not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(8)) {
        justify-content: center !important;
      }
    }
  }
}

#group-description {
  .traceback__menu {
    display: none;
  }
}

.traceback__menu {
  display: block !important;
}

.date-picker-large {
  min-height: 28px;
}

.media-query-wrap {
  @media all and(max-width:576px) {
    flex-direction: column;
  }
}

.downloadCsvStyle {
  height: 28px;
  padding: 5px;
  font-size: 14px;
  @media all and (max-width: 992px) {
    margin-top: 10px;
  }
}

.padding-top-conditional {
  @media all and(max-width:1200px) {
    padding-top: 0.5rem;
  }
}

.traceback-dropdown {
  .popover {
    padding: 0.5rem 1rem !important;
    max-width: 180px;
    // transform: translate3d(680px, 62px, 0px) !important;
    border-top: 2px solid #163863;
    border-radius: 0;
  }

  .popover-body {
    list-style: none;
    .nav-link {
      font-family: 'Avenir-Medium', serif;
      font-weight: 800;
      padding: 0;
      color: #163863;
      text-align: right !important;
      margin-bottom: 0;
      text-transform: uppercase;
      cursor: pointer;

      &:nth-child(1) {
        margin-bottom: 1rem;
      }

      i {
        font-size: 10px;
        opacity: 0.4;
        color: green;
        padding-left: 8px;
        visibility: hidden;
      }
    }

    .active {
      i {
        visibility: visible;
      }
    }
  }
}
