.row-center {
  position: absolute;
  top: 92%;
  margin-left: 33%;
}

.similar-provider {
  cursor: pointer;
  font-weight: 600;
}

.rmd-results {
  overflow: auto;
  height: 50vh;
}

.rmd-providerName {
  text-align: left;
}

.duplicate-rmd {
  background-color: #e6e6e6;
}

.hr-line {
  background: grey;
  border-width: 2;
}

.bottom-options {
  position: absolute;
  bottom: 30;
}
