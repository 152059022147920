.navMenu {
  .nav-link {
    @media all and (min-width: 1260px) and (max-width: 1600px) {
      font-size: 18px;
      padding: 0.5rem 0.3rem;
    }
    @media all and (min-width: 1200px) and (max-width: 1259px) {
      font-size: 14px;
      padding: 0.5rem 0.1rem;
    }
  }
}
