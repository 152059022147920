a:focus,
button:focus,
.btn:focus {
  outline: none;
  border: 0px;
}

.form-group {
  margin: 0 auto;
  margin-bottom: 1.2rem;
}

.login-form-group {
  max-width: 305px;
  margin-bottom: 25px !important;
  margin-left: auto;
  margin-right: auto;
}

.form-group-border {
  border: 1px solid $border-color;
}

.control-error {
  border: 1px solid $error-textColor !important;
}

.custom-checkbox .remember-me::before {
  border-radius: 0 !important;
}

.btn-login {
  width: 80%;
}

.btn-link {
  color: $primary-link-color !important;
  text-decoration: none !important;
}

.text-welcome {
  font-family: 'Futura-Medium';
  font-size: $title-fontSize;
  color: $welcome-title-color;
  letter-spacing: 2px;
}

.sub-text-welcome {
  color: $welcome-textColor;
  font-family: 'Calibri';
  font-size: 17px;
}

.reset-password-text {
  color: $welcome-textColor;
  font-family: 'Avenir-Medium';
  font-size: 25px;
}

.welcome-sub-bold {
  color: $darkblue;
  font-family: 'Calibri-Bold';
  font-size: 1rem;
  font-weight: 700;
}

.password-rules {
  font-size: 0.75rem;
  color: #dedede;
  display: inline-block;
  align-items: center;
  width: 50%;
}

.password-rules span {
  background: #ebebeb;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.checkbox-remember {
  align-items: center;
  margin-left: 0;
  margin-right: 0;

  input[type='checkbox'] {
    cursor: pointer;
  }

  .remember-me {
    color: $remember-me-color;
    font-size: $text-fontSize;
    font-weight: normal;
    font-family: 'Calibri';
  }
}

.telecom-btn {
  text-transform: capitalize !important;
  min-width: 130px;
  max-height: 40px;
  padding: 5px 25px;
}

.forgot-password {
  font-family: 'Calibri';
  font-size: $text-fontSize;
}

.telecom-input-error {
  margin: 4px 0;
}

.card-sign {
  margin: 60px 0px 60px 0px;
  height: calc(80vh - 60px);
  min-height: 250px;
  overflow: auto;
}

.card-password-reset {
  margin: $card-forgot-password-margin;
}

.card-sent-email {
  margin: $card-sent-email-margin;
}

.card-new-password {
  margin: $card-new-password-margin;
}

.sent-email-page {
  padding: 80px 0px;
  .sent-email-message {
    color: '#526c88';
    font-size: '19px';
  }
  .support {
    cursor: pointer;
    color: 'blue';
  }
}
