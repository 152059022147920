.card-account {
  .form-group {
    margin-bottom: $account-form-group-margin-bottom;
  }
}

.telecom-label {
  display: block;
  color: $label-textColor;
  font-size: $text-fontSize;
  font-family: 'Calibri-Bold';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.telecom-label-invert {
  display: block;
  color: white;
  font-size: $text-fontSize;
  font-family: 'Calibri-Bold';
  padding-left: 0.5rem;
  margin-bottom: 3px;
}

.form-account_text {
  color: $input-textColor;
  font-family: 'Calibri';
  font-size: $input-text-fontSize;
  padding-left: 12px;
}

.myaccount-input {
  border-color: transparent !important;
}
