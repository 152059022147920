body {
  -webkit-animation: fadein $fadeIn-time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $fadeIn-time; /* Firefox < 16 */
  -ms-animation: fadein $fadeIn-time; /* Internet Explorer */
  -o-animation: fadein $fadeIn-time; /* Opera < 12.1 */
  animation: fadein $fadeIn-time;
}

.form-fadein {
  -webkit-animation: fadein $form-fadeIn-time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $form-fadeIn-time; /* Firefox < 16 */
  -ms-animation: fadein $form-fadeIn-time; /* Internet Explorer */
  -o-animation: fadein $form-fadeIn-time; /* Opera < 12.1 */
  animation: fadein $form-fadeIn-time;
}

.text-fadein {
  -webkit-animation: fadein $text-fadeIn-time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $text-fadeIn-time; /* Firefox < 16 */
  -ms-animation: fadein $text-fadeIn-time; /* Internet Explorer */
  -o-animation: fadein $text-fadeIn-time; /* Opera < 12.1 */
  animation: fadein $text-fadeIn-time;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
