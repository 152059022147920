.data-table {
  // Format of Table Height
  .rdt_TableBody {
    max-height: $datatable-maxHeight;
    min-height: $datatable-minHeight;
  }

  // Format of Table Header Background
  .rdt_TableHeadRow {
    background: $darkblue !important;
    font-family: Avenir-Light !important;
    min-height: 55px;
  }

  // Format of Table Row Background
  .rdt_TableRow {
    border-bottom-style: solid !important;
    border-bottom-width: 2px !important;
    border-bottom-color: rgba(243, 243, 243, 1) !important;
    min-height: 55px !important;
    //white-space: nowrap !important;

    // Dropdown Button Format in Column 1 of Campaign Page
    button {
      color: $title-textColor !important;
      font-family: Calibri-Bold !important;
      background: transparent !important;

      &:hover {
        background: transparent !important;
      }

      &:active {
        background: transparent !important;
        fill: none !important;
      }

      div {
        &:active {
          background: transparent !important;
        }
      }
    }

    button:disabled,
    button[disabled] {
      visibility: hidden;
    }

    &:hover {
      background: rgba(243, 243, 243, 1);
      border-bottom-color: rgba(221, 221, 221, 1) !important;

      .rdt_TableCell {
        background: rgba(243, 243, 243, 1) !important;
      }
    }
  }

  // Format of Text within Table Header
  .rdt_TableCol,
  .rdt_TableCol_Sortable {
    color: $white !important;
    font-size: 1rem !important;
    padding: 0 !important;
    width: 100%;
    justify-content: center;

    [data-column-id="column-callDate"],
    [data-column-id="column-spokeWith"],
    [data-column-id="column-userName"],
    [data-column-id="column-contentText"],
    [data-column-id="column-userProviderName"],
    [data-column-id="column-providerName"],
    [data-column-id="column-hopProviderName"],
    [data-column-id="column-recipientEmail"],
    [data-column-id="column-create_date"],
    [data-column-id="column-update_date"],
    [data-column-id="column-campaignName"],
    [data-column-id="column-hopProvider"],
    [data-column-id="column-recipientProviderName"],
    [data-column-id="column-upstreamProviderName"],
    [data-column-id="column-downstreamProviderName"],
    [data-column-id="column-id"],
    [data-column-id="column-tracebackId"],
    [data-column-id="column-lastProviderName"],
//  [data-column-id="column-avgResponseMinOfHops"],
    [data-column-id="column-emailType"],
    [data-column-id="column-name"],
    [data-column-id="column-accessDate"],
    [data-column-id="column-description"],
    [data-column-id="column-campaignLabel "] {
      justify-content: left !important;
    }

    // Format of Sort Button (Green Triangle) within Table Header
    span {
      color: $green;

      &:active,
      &:hover {
        color: $green;
      }

      i {
        max-width: 10px !important;
        max-height: 10px !important;
        transition-duration: 0ms !important;
      }
    }

    .asc,
    .desc {
      position: inherit;
      transform: translate(5px, 5px);
    }
  }

  // Format of Text within Table Rows
  .rdt_TableCell {
    div:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    font-family: Calibri !important;
    font-size: 1rem !important;
    padding: 0 !important;
    color: #494949;
    justify-content: flex-start;
  }

  .rdt_ExpanderRow {
    border-bottom-style: solid !important;
    border-bottom-width: 2px !important;
    border-bottom-color: rgba(243, 243, 243, 1) !important;
  }

  .table-cell {
    flex-grow: inherit;
    font-family: Calibri;

    &.bold {
      font-family: Calibri-Bold;
    }

    a {
      text-decoration: none;
    }

    &.center {
      text-align: center !important;
    }

    &.right {
      text-align: right !important;
    }

    &.left {
      text-align: left !important;
    }

    &.blue {
      color: $title-secondary-textColor;

      a {
        color: $title-secondary-textColor;
      }
    }

    &.black {
      color: black;

      a {
        color: black;
      }
    }

    &.gray {
      color: $input-textColor;

      a {
        color: $input-textColor;
      }
    }

    &.fa-circle {
      transform: translate(5px, 5px);
    }

    .red-button {
      color: red !important;
    }

    &.red {
      color: red !important;
    }

    &.wrap-text {
      white-space: pre-wrap !important;
    }
    &.edit {
      cursor: pointer;
    }
  }
}

// Format of Pagination Below Table
nav {
  &.rdt_Pagination {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: Calibri-Bold !important;
    font-size: 16px !important;
    font-weight: 200 !important;
    color: $gray !important;
  }
}
