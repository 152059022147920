.notificationBell {
  color: #07335e;
  font-size: 32px;
  @media all and (max-width: 1600px) {
    font-size: 26px;
  }
}

.notificationText {
  display: block;
  color: $label-textColor;
  font-size: 14px;
  font-family: 'Calibri-Bold';
  padding-left: 0.5rem;
}
