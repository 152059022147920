.providerSummary-title-container {
  margin: 0 3rem 0 0;
  align-items: center;
  .follow-provider-input-container {
    display: flex;
    align-items: center;
    padding: 1rem 0 0.5rem 3.5rem;
  }
}

.provider-title-container {
  max-width: $card-maxWidth;
  margin: 0 auto;
  align-items: center;
  .follow-provider-input-container {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
}

.originChartCol {
  @media screen and (max-width: 1200px) {
    padding-top: 3rem;
  }
}
