.homePage {
  .homePage-hr {
    width: 80vw;
    margin: 0 auto;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
  .originChartCol {
    @media screen and (max-width: 1200px) {
      padding-top: 3rem;
    }
  }
  .mapChart {
    column-gap: 2rem;
    .mapCol {
      border: 1px solid #0f569e;
    }
  }
  .itg-metrics-title {
    color: rgb(71, 167, 133) !important;
  }
}
